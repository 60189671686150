const ENDPOINT = "https://padron.sigue.septlaxcala.gob.mx/api/v1";

export async function Login(data) {
  return fetch(`${ENDPOINT}/login`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Authorization": "Bearer c5f47d53-4a2a-467a-bb65-63746b5092cc",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
