import React from "react";
import DotLoader from "react-spinners/DotLoader";

export default function Loader() {
  return (
    <div
      className="h-screen flex flex-col 
                    items-center justify-center"
    >
      <DotLoader sizeUnit={"px"} size={100} color={"#000"} loading={true} />
    </div>
  );
}
