import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import Page404 from "../pages/404";
import PrivateRoute from "./PrivateRoute";
import Loader from '../components/Loader';
import { UserContextProvider } from "../context/userContext";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Programas = lazy(() => import("../pages/Programas"));
const ActoresSociales = lazy(() => import("../pages/ActoresSociales"));
const ArchivoElectronico = lazy(() => import("../pages/ArchivoElectronico"));
const Beneficiario = lazy(() => import("../pages/Beneficiario"));
const Beneficio = lazy(() => import("../pages/Beneficio"));
const Catalogos = lazy(() => import("../pages/Catalogos"));
const PUU = lazy(() => import("../pages/PUU"));
const Usuarios = lazy(() => import("../pages/Usuarios"));

// catalogos
const Estados = lazy(() => import("../pages/Catalogos/Estados"));
const EstadoCivil = lazy(() => import("../pages/Catalogos/EstadoCivil"));
const EnfermedadesCronicas = lazy(() =>
  import("../pages/Catalogos/EnfermedadesCronicas")
);
const Localidades = lazy(() => import("../pages/Catalogos/Localidades"));
const Municipios = lazy(() => import("../pages/Catalogos/Municipios"));
const NivelEducativo = lazy(() => import("../pages/Catalogos/NivelEducativo"));
const Ocupacion = lazy(() => import("../pages/Catalogos/Ocupacion"));
const Sexo = lazy(() => import("../pages/Catalogos/Sexo"));
const TipoDocumento = lazy(() => import("../pages/Catalogos/TipoDocumento"));
const TipoSangre = lazy(() => import("../pages/Catalogos/TipoSangre"));
export default function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/inicio"
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="/actores-sociales"
            element={
              <Suspense fallback={<Loader />}>
                <ActoresSociales />
              </Suspense>
            }
          />
          <Route
            path="/beneficiario"
            element={
              <Suspense fallback={<Loader />}>
                <Beneficiario />
              </Suspense>
            }
          />
          <Route
            path="/beneficio"
            element={
              <Suspense fallback={<Loader />}>
                <Beneficio />
              </Suspense>
            }
          />
          <Route
            path="/archivo-electronico"
            element={
              <Suspense fallback={<Loader />}>
                <ArchivoElectronico />
              </Suspense>
            }
          />
          <Route
            path="/catalogos"
            element={
              <Suspense fallback={<Loader />}>
                <Catalogos />
              </Suspense>
            }
          />
          <Route
            path="/estados"
            element={
              <Suspense fallback={<Loader />}>
                <Estados />
              </Suspense>
            }
          />
          <Route
            path="/estado-civil"
            element={
              <Suspense fallback={<Loader />}>
                <EstadoCivil />
              </Suspense>
            }
          />
          <Route
            path="/enfermedades-cronicas"
            element={
              <Suspense fallback={<Loader />}>
                <EnfermedadesCronicas />
              </Suspense>
            }
          />
          <Route
            path="/localidades"
            element={
              <Suspense fallback={<Loader />}>
                <Localidades />
              </Suspense>
            }
          />
          <Route
            path="/municipios"
            element={
              <Suspense fallback={<Loader />}>
                <Municipios />
              </Suspense>
            }
          />
          <Route
            path="/nivel-educativo"
            element={
              <Suspense fallback={<Loader />}>
                <NivelEducativo />
              </Suspense>
            }
          />
          <Route
            path="/ocupacion"
            element={
              <Suspense fallback={<Loader />}>
                <Ocupacion />
              </Suspense>
            }
          />
          <Route
            path="/sexo"
            element={
              <Suspense fallback={<Loader />}>
                <Sexo />
              </Suspense>
            }
          />
          <Route
            path="/tipo-documento"
            element={
              <Suspense fallback={<Loader />}>
                <TipoDocumento />
              </Suspense>
            }
          />
          <Route
            path="/tipo-sangre"
            element={
              <Suspense fallback={<Loader />}>
                <TipoSangre />
              </Suspense>
            }
          />
          <Route
            path="/programas"
            element={
              <Suspense fallback={<Loader />}>
                <Programas />
              </Suspense>
            }
          />
          <Route
            path="/puu"
            element={
              <Suspense fallback={<Loader />}>
                <PUU />
              </Suspense>
            }
          />
          <Route
            path="/usuario"
            element={
              <Suspense fallback={<Loader />}>
                <Usuarios />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </UserContextProvider>
  );
}
